import { getTheme } from "@fluentui/react";

import React, { Component } from "react";
import { connect } from "react-redux";
import { getAssetIdFromSystem } from "../../../../../util";
import { defaultConfig } from "../../forms/defaultConfigs/gauge";
import CanvasGauge from "./CanvasGauge2";

class Gauge extends Component {
	static defaultProps = defaultConfig();
	assetId = getAssetIdFromSystem(null, this.props.properties.slotPath);
	cleanColor(color) {
		if (color?.length == 6 || color?.length == 3) {
			return "#" + color;
		} else {
			return color;
		}
	}
	nFormatter(num, digits) {
		var si = [
			{ value: 1, symbol: "" },
			{ value: 1e3, symbol: "k" },
			{ value: 1e6, symbol: "M" },
			{ value: 1e9, symbol: "G" },
			{ value: 1e12, symbol: "T" },
			{ value: 1e15, symbol: "P" },
			{ value: 1e18, symbol: "E" },
		];
		var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
		var i;
		for (i = si.length - 1; i > 0; i--) {
			if (num >= si[i].value) {
				break;
			}
		}
		return (
			(num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol
		);
	}

	render() {
		var properties = defaultConfig();

		var theme = getTheme();
		Object.assign(properties, this.props.properties);
		const max = parseFloat(properties.max);
		const min = parseFloat(properties.min);

		var ticks = [];
		var dataItem = this.props.properties.dataItem;
		if (dataItem?.value) {
			delete dataItem.value;
		}

		if (dataItem) {
			Object.assign(
				dataItem,
				this.props.currentData?.[this.assetId]?.[dataItem.dataItemId]
			);
			var { unit, value } = dataItem;
			var delta = (max - min) / 10;
			for (var i = 0; i < 11; i++) {
				ticks.push(this.nFormatter(min + i * delta, 2));
			}
			//console.log(this.props.properties.settingsGuid,unit,value)
			var simpleStyle = {
				gaugeProps: {
					units: unit,
					title: dataItem?.alias ?? dataItem?.label,
					value: value,
					minValue: min,
					maxValue: max,
					colorBorderOuter: "#00000000",
					colorBorderOuterEnd: "#00000000",
					colorBorderMiddle: "#00000000",
					colorBorderMiddleEnd: "#00000000",
					colorBorderInner: "#00000000",
					colorBorderInnerEnd: "#00000000",
					colorNeedleCircleOuter: "#444",
					colorNeedleCircleOuterEnd: "#444",
					colorNeedleCircleInner: "#444",
					colorNeedleCircleInnerEnd: "#444",
					colorMajorTicks: "#00000000",
					needleShadow: false,
					valueDec: properties.decimals,
					colorMinorTicks: "#00000000",
					colorTitle: "#ddd",
					//highlightsWidth:10,
					needlineType: "line",
					fontTitle: "Proxima Nova Thin",
					ticksAngle: 240,
					startAngle: 60,
					valueBox: false,
					valueTextShadow: false,
					colorUnits: "#ddd",
					colorNumbers: "#ddd",
					colorPlate: "#2a2a2a",
					colorPlateEnd: "#1e1e1e00",
					highlights: [
						{
							from: min,
							to: properties.band1End,
							color: this.cleanColor(properties.band1),
						},
						{
							from: properties.band1End,
							to: properties.band2End,
							color: this.cleanColor(properties.band2),
						},
						{
							from: properties.band2End,
							to: max,
							color: this.cleanColor(properties.band3),
						},
					],
					majorTicks: ticks,
				},
			};
			var darkStyle = {
				gaugeProps: {
					units: unit,
					title: dataItem?.alias ?? dataItem?.label,
					value: value,
					minValue: min,
					maxValue: max,
					colorBorderOuter: "#00000000",
					colorBorderOuterEnd: "#00000000",
					colorBorderMiddle: "#00000000",
					colorBorderMiddleEnd: "#00000000",
					colorBorderInner: "#00000000",
					colorBorderInnerEnd: "#00000000",
					colorNeedleCircleOuter: theme.palette.neutralDark,
					colorNeedleCircleOuterEnd: theme.palette.neutralDark,
					colorNeedleCircleInner: theme.palette.neutralDark,
					colorNeedleCircleInnerEnd: theme.palette.neutralDark,
					colorMajorTicks: theme.palette.neutralDark,
					needleShadow: false,
					valueDec: properties.decimals,
					colorMinorTicks: theme.palette.neutralDark,
					colorTitle: theme.palette.neutralDark,
					//highlightsWidth:10,
					fontTitle: "Proxima Nova Thin",
					ticksAngle: 240,
					startAngle: 60,
					valueBox: true,
					valueTextShadow: false,
					colorUnits: theme.palette.neutralDark,
					colorNumbers: theme.palette.neutralDark,
					colorPlate: theme.palette.neutralLight,
					colorPlateEnd: theme.palette.neutralLight + "00",
					highlights: [
						{
							from: min,
							to: properties.band1End,
							color: this.cleanColor(properties.band1),
						},
						{
							from: properties.band1End,
							to: properties.band2End,
							color: this.cleanColor(properties.band2),
						},
						{
							from: properties.band2End,
							to: max,
							color: this.cleanColor(properties.band3),
						},
					],
					majorTicks: ticks,
				},
			};
			var defaultStyle = {
				gaugeProps: {
					units: unit,
					title: dataItem?.alias ?? dataItem?.label,
					value: value,
					minValue: min,
					maxValue: max,
					needleShadow: false,
					valueDec: properties.decimals,
					highlightsWidth: 10,
					fontTitle: "Proxima Nova Thin",
					valueBox: true,
					valueTextShadow: false,
					highlights: [
						{
							from: min,
							to: properties.band1End,
							color: this.cleanColor(properties.band1),
						},
						{
							from: properties.band1End,
							to: properties.band2End,
							color: this.cleanColor(properties.band2),
						},
						{
							from: properties.band2End,
							to: max,
							color: this.cleanColor(properties.band3),
						},
					],
					majorTicks: ticks,
				},
			};

			var chosenStyle;
			if (properties.style == "default") {
				chosenStyle = defaultStyle;
			} else if (properties.style == "simple") {
				chosenStyle = simpleStyle;
			} else if (properties.style == "dark") {
				chosenStyle = darkStyle;
			} else {
				chosenStyle = simpleStyle;
			}

			chosenStyle.gaugeProps.fontNumbersSize =
				properties.fontNumbersSize ?? 24;
			chosenStyle.gaugeProps.fontTitleSize =
				properties.fontTitleSize ?? 30;
			chosenStyle.gaugeProps.fontUnitsSize =
				properties.fontUnitsSize ?? 30;
			chosenStyle.gaugeProps.fontValueSize =
				properties.fontValueSize ?? 24;
			if (properties?.half ?? true) {
				//halfcircle
				chosenStyle.containerProps = { style: { height: "100%" } };
				chosenStyle.gaugeProps.startAngle = 90;
				chosenStyle.gaugeProps.ticksAngle = 180;
			} else {
				chosenStyle.containerProps = { style: { height: "100%" } };
			}

			chosenStyle.gaugeProps.numbersMargin = false ? -35 : 0;
			return (
				<div {...chosenStyle.containerProps}>
					<CanvasGauge
						key={this.props.settingsGuid}
						settingsGuid={this.props.settingsGuid}
						{...chosenStyle.gaugeProps}
					></CanvasGauge>
				</div>
			);
		} else {
			return <div>Cannot find data item</div>;
		}
	}
}
function mapStateToProps({ currentData }) {
	return { currentData };
}
export default connect(mapStateToProps)(Gauge);
