import { DefaultButton, hsv2rgb, PrimaryButton } from "@fluentui/react";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createAttribute, deleteAttribute } from "../../../../../actions";
import {
	getAssetFromSystemByAssetId,
	getAssetIdFromSystem,
	getBooleanAttribute,
	getJobEndTimeAttribute,
	getJobStartTimeAttribute,
	getNumericAttribute,
} from "../../../../../util";

export default function SnubWellKPIs({ filteredSchedule, system, depth, plugCount, onlyProgress, scale }) {
	if (!scale) scale = 1
	var trippingRate = getBooleanAttribute("high pressure") ? 45 : 55;

	
	//let [plugCount, setPlugCount] = useState(defaultPlug);
	// useEffect(() => {
	// 	fetch(
	// 		`/api/HISTORICALDATA?columnar=true&dataItems=${assetId}-39&startTime=${getJobStartTimeAttribute().toJSON()}&endTime=${getJobEndTimeAttribute().toJSON()}`
	// 	)
	// 		.then((r) => r.json())
	// 		.then((s) => s[1].map((s) => parseFloat(s)))
	// 		.then((d) => {
	// 			console.log(d);
	// 			var maxPlug = Math.max(...d);
	// 			setPlugCount(maxPlug);
	// 		});
	// }, [system.assetId]);
	let td = getNumericAttribute("Total Depth (ft)", 0, system);
	let kd = getNumericAttribute("Kill Depth (ft)", 0, system);

	let totalDrillTime = filteredSchedule
		.filter((schditem) => ["Drill"].indexOf(schditem.activity) >= 0)
		?.reduce((accum, schedItem) => accum + schedItem.hours, 0);

	let [focusedKPI, setFocusedKPI] = useState();
	const dispatch = useDispatch();
	let KPIs = useMemo(() => {
		var previousActivity = "";
		var tihProgress = 0;
		var tohProgress = 0;
		var mwProgess = 0;
		var tihActive = 0;
		var tohActive = 0;
		var mwActive = 0;

		for (var i = 0; i < filteredSchedule.length; i++) {
			// var depth =
			// 	filteredSchedule[i].depth ??
			// 	this.props.currentData?.[this.props.system.assetId]?.[19]
			// 		.value ??
			// 	depth;
			var depth = filteredSchedule[i].depth ?? depth;

			previousActivity = filteredSchedule[i].activity;
			if (previousActivity == "Tripping In") {
				var newProgress = depth / kd;
				if (newProgress > tihProgress) {
					tihProgress = newProgress;
					tihActive = depth;
				}
			}
			if (previousActivity == "Tripping Out") {
				var newProgress = (td - depth) / td;
				if (newProgress > tohProgress) {
					tohProgress = newProgress;
					tohActive = td - depth;
				}
			}
			if (previousActivity == "Washdown" || previousActivity == "Drill") {
				var newProgress = (depth - kd) / (td - kd);
				if (newProgress > mwProgess) {
					mwProgess = newProgress;
					mwActive = depth - kd;
				}
			}
		}
		const defaultKPIs = [
			{
				label: (kpi) => "Score",
				value: (kpi) =>
					isFinite(kpi.score) ? kpi.score?.toFixed(0) + "%" : "- -",
			},
			{
				label: (kpi) => "Current Hours on Task",
				value: (kpi) =>
					isFinite(kpi.actualHours)
						? kpi.actualHours?.toFixed(1)
						: "- -",
			},
			{
				label: (kpi) => "Active Benchmark Hours",
				value: (kpi) =>
					isFinite(kpi.progress)
						? (kpi.progress * kpi.benchmarkHours)?.toFixed(1)
						: "- -",
			},
			{
				label: (kpi) => "Final Benchmark Hours",
				value: (kpi) =>
					isFinite(kpi.benchmarkHours)
						? kpi.benchmarkHours?.toFixed(1)
						: "- -",
			},
			{
				label: (kpi) => "Task Completion",
				value: (kpi) =>
					isFinite(kpi.progress)
						? (kpi.progress * 100)?.toFixed(0) + "%"
						: "- -",
			},
		];

		const trippingKPIs = [
			{
				label: () => "Joints per Hour",
				value: (kpi) => {
					return isFinite(
						(kpi.benchmark * kpi.progress) / 31 / kpi.actualHours
					)
						? (
								(kpi.benchmark * kpi.progress) /
								31 /
								kpi.actualHours
						  )?.toFixed(1)
						: "- -";
				},
			},
			...defaultKPIs,
		];
		var rigUpType =
			filteredSchedule
				.filter(
					(schditem) => ["Rig Up"].indexOf(schditem.activity) >= 0
				)
				?.reduce((accum, schedItem) => accum + schedItem.hours, 0) > 0
				? {
						activity: "Rig Up",
						benchmarkHours: 9,
						actualHours: filteredSchedule
							.filter(
								(schditem) =>
									["Rig Up"].indexOf(schditem.activity) >= 0
							)
							?.reduce(
								(accum, schedItem) => accum + schedItem.hours,
								0
							),
						taskKPIs: defaultKPIs,
				  }
				: {
						activity: "Rig Over",
						benchmarkHours: 5,
						actualHours: filteredSchedule
							.filter(
								(schditem) =>
									["Rig Over"].indexOf(schditem.activity) >= 0
							)
							?.reduce(
								(accum, schedItem) => accum + schedItem.hours,
								0
							),
						taskKPIs: defaultKPIs,
				  };

		var trippingType =
			filteredSchedule
				.filter(
					(schditem) =>
						["Tripping In Prod"].indexOf(schditem.activity) >= 0
				)
				?.reduce((accum, schedItem) => accum + schedItem.hours, 0) > 0
				? {
						activity: "Tripping In Prod",
						benchmarkHours:
							getNumericAttribute(
								"Prod. Tubing Depth (ft)",
								0,
								system
							) /
							(31 * trippingRate),
						actualHours: filteredSchedule
							.filter(
								(schditem) =>
									["Tripping In Prod"].indexOf(
										schditem.activity
									) >= 0
							)
							?.reduce(
								(accum, schedItem) => accum + schedItem.hours,
								0
							),
						benchmark: getNumericAttribute(
							"Prod. Tubing Depth (ft)",
							0,
							system
						),
						taskKPIs: trippingKPIs,
				  }
				: {
						activity: "Tripping In",
						benchmarkHours:
							getNumericAttribute("Kill Depth (ft)", 0, system) /
							(31 * trippingRate),
						actualHours: filteredSchedule
							.filter(
								(schditem) =>
									["Tripping In"].indexOf(
										schditem.activity
									) >= 0
							)
							?.reduce(
								(accum, schedItem) => accum + schedItem.hours,
								0
							),
						progress: tihProgress,
						benchmark: getNumericAttribute(
							"Kill Depth (ft)",
							0,
							system
						),
						activeBenchmark: tihActive,
						taskKPIs: trippingKPIs,
				  };
		var KPIs = [
			rigUpType,
			{
				activity: "BOP Press Test",
				benchmarkHours:
					getNumericAttribute("BOP Count", 0, system) * 0.5,
				actualHours: filteredSchedule
					.filter(
						(schditem) =>
							["BOP Press Test"].indexOf(schditem.activity) >= 0
					)
					?.reduce((accum, schedItem) => accum + schedItem.hours, 0),
				taskKPIs: defaultKPIs,
			},

			trippingType,
			{
				activity: "Mill and Wash",
				benchmarkHours:
					(getNumericAttribute("Total Depth (ft)", 0, system) -
						getNumericAttribute("Kill Depth (ft)", 0, system)) /
					(31 * 10),
				actualHours: filteredSchedule
					.filter(
						(schditem) =>
							["Drill", "Washdown"].indexOf(schditem.activity) >=
							0
					)
					?.reduce((accum, schedItem) => accum + schedItem.hours, 0),
				progress: mwProgess,
				benchmark:
					getNumericAttribute("Total Depth (ft)", 0, system) -
					getNumericAttribute("Kill Depth (ft)", 0, system),
				activeBenchmark: mwActive,
				taskKPIs: trippingKPIs,
			},
			{
				activity: "Tripping Out",
				benchmarkHours:
					getNumericAttribute("Total Depth (ft)", 0, system) /
					(31 * trippingRate),
				progress: tohProgress,
				actualHours: filteredSchedule
					.filter(
						(schditem) =>
							["Tripping Out"].indexOf(schditem.activity) >= 0
					)
					?.reduce((accum, schedItem) => accum + schedItem.hours, 0),
				benchmark: getNumericAttribute("Total Depth (ft)", 0, system),
				activeBenchmark: tohActive,
				taskKPIs: trippingKPIs,
			},
			{
				activity: "Rig Down",
				benchmarkHours: 9,
				actualHours: filteredSchedule
					.filter(
						(schditem) =>
							["Rig Down"].indexOf(schditem.activity) >= 0
					)
					?.reduce((accum, schedItem) => accum + schedItem.hours, 0),
				taskKPIs: defaultKPIs,
			},
		];
		KPIs.forEach((el) => {
			if (
				getNumericAttribute(
					`activity-complete-${el.activity}`,
					0,
					system
				) == 1
			) {
				el.progress = 1;
			}
			el.progress =
				el.progress ?? Math.min(1, el.actualHours / el.benchmarkHours);
			el.score =
				(el.progress / (el.actualHours / el.benchmarkHours)) * 100;
		});
		return KPIs;
	}, [filteredSchedule, system]);

	useEffect(() => {
		var newFocused = KPIs.find((k) => k?.activity == focusedKPI?.activity);
		setFocusedKPI(newFocused);
	}, KPIs);
	return (
		<>
		{!onlyProgress && 
			<div
				style={{
					marginLeft: 15,
					marginRight: 15,
					display: "inline-flex",
					flexDirection: "row",
					alignItems: "center",
					gap: 15,
					fontSize:scale*12,
					justifyContent: "space-around",
				}}
			>
				<KPIIndictor
					value={(
						(new Date() - getJobStartTimeAttribute(system)) /
						(3600 * 1000 * 24)
					)?.toFixed(1)}
					label="Days on Well"
				></KPIIndictor>

				<KPIIndictor
					value={plugCount?.toFixed(0) +"/"+getNumericAttribute("Plug Count", 0, system)}
					label="Current Plug"
				></KPIIndictor>
				<KPIIndictor
					value={
						plugCount > 0
							? ((totalDrillTime * 60) / plugCount)?.toFixed(1)
							: "- -"
					}
					label="Minutes per Plug"
				></KPIIndictor>
				<KPIIndictor
					value={
						plugCount > 0
							? (
									plugCount /
									KPIs.find(
										(k) => k.activity == "Mill and Wash"
									).actualHours
							  )?.toFixed(1)
							: "- -"
					}
					label="Plugs per Hour"
				></KPIIndictor>
				<KPIIndictor
					value={(
						(depth - kd) /
						KPIs.find((k) => k.activity == "Mill and Wash")
							.actualHours
					)?.toFixed(1)}
					label="Lateral Feet per Hour"
				></KPIIndictor>
			</div>}
			<div
				style={{
					overflow: "hidden",
					borderRadius: 200,

					display: "inline-flex",
					boxSizing: "border-box",
					border: "1px solid white",

					margin: 15,
				}}
			>
				{KPIs.filter((kpi) => kpi.benchmarkHours).map((kpi) => {
					var progress = kpi.progress * 100;

					var color = colorFromScore(kpi.score);
					return (
						<div
							onClick={() => {
								
								setFocusedKPI((prev) => {
									if (prev?.activity == kpi?.activity) {
										setFocusedKPI(null);
									} else {
										setFocusedKPI(kpi);
									}
								});
							}}
							style={{
								display: "flex",
								alignItems: "center",
								overflowX: "hidden",
								boxSizing: "border-box",
								position: "relative",
								minHeight: scale * 55,
								padding: 6,
								fontSize:scale*12,
								textShadow:
									"-1px 1px 0 #000,1px 1px 0 #000,1px -1px 0 #000,-1px -1px 0 #000",
								boxShadow:
									kpi?.activity == focusedKPI?.activity
										? "inset 0 -6px 10px #ffffddbb,inset 0 6px 10px #ffffddaa"
										: "inset 0 -9px 10px #00000066,inset 0 9px 10px #ffffff66",
								justifyContent: "center",
								borderRight: "1px solid white",
								transition: "all .4s",
								background: `repeating-linear-gradient(-45deg,#00000000,#00000000 15px, #00000033 20px,#00000033 30px,#00000000 35px),linear-gradient(90deg, ${color} 0%,  ${color} ${progress}%, transparent ${progress}%, transparent 100%`,
								flexGrow: `${
									(kpi.benchmarkHours /
										KPIs?.reduce(
											(acc, curr) =>
												acc + curr.benchmarkHours,
											0
										)) *
									(kpi?.activity == focusedKPI?.activity
										? 150
										: 100)
								}`,
							}}
						>
							<span
								style={{
									textAlign: "center",
									fontSize:scale*12
									
								}}
							>
								<p style={{fontSize:"inherit"}}>{kpi.activity}</p>
								{kpi.progress < 1 ? (
									<p style={{fontSize:"inherit"}}>
										Completion:{" "}
										{parseInt(
											Math.min(
												Math.max(kpi.progress, 0),
												1
											) * 100
										)}
										%
									</p>
								) : (
									<p style={{fontSize:"inherit"}}>Final Score: {parseInt(kpi.score)}%</p>
								)}
							</span>
						</div>
					);
				})}
			</div>
			{ !onlyProgress && [focusedKPI].map((kpi) => {
				if (!kpi) return <div></div>;
				else var progress = kpi.progress * 100;
				var color = colorFromScore(kpi.score);
				return (
					<div
						style={{
							textAlign: "center",
							margin: "0px 15px 0px 15px",
						}}
					>
						<p style={{ fontSize: scale*24 }}>
							{focusedKPI.activity} KPIs
						</p>
						
						<div
							style={{
								width: "100%",
								margin: "15px 0 15px 0px",
								display: "inline-flex",
								flexDirection: "row",
								alignItems: "center",
								gap: 15,
								justifyContent: "space-around",
							}}
						>
							{kpi.taskKPIs?.map((tkpi) => (
								<KPIIndictor
									label={tkpi.label(kpi)}
									value={tkpi.value(kpi)}
								></KPIIndictor>
							))}
						</div>

						{getBooleanAttribute(
							`activity-complete-${kpi.activity}`,
							0,
							system
						) == 0 ? (
							<PrimaryButton
								text="Mark as complete"
								onClick={() => {
									dispatch(
										createAttribute({
											attributeName: `activity-complete-${kpi.activity}`,
											attributeValue: "1",
										})
									);
								}}
							></PrimaryButton>
						) : (
							<DefaultButton
								text="Unmark as complete"
								onClick={() => {
									dispatch(
										deleteAttribute({
											attributeName: `activity-complete-${kpi.activity}`,
										})
									);
								}}
							></DefaultButton>
						)}
					</div>
				);
			})}
		</>
	);
}
function colorFromScore(score) {
	var color = "rgb(128,0,0)";
	if (score > 70 && score < 80) {
		color = pickHex([128, 0, 0], [255, 128, 0], (score - 70) / 10);
	} else if (score >= 80 && score < 90) {
		color = pickHex([255, 128, 0], [25, 150, 0], (score - 80) / 10);
	} else if (score >= 90) {
		color = "rgb(25,150,0)";
	}
	return color;
}
function pickHex(color2, color1, weight) {
	var w1 = weight;
	var w2 = 1 - w1;
	var rgb = [
		Math.round(color1[0] * w1 + color2[0] * w2),
		Math.round(color1[1] * w1 + color2[1] * w2),
		Math.round(color1[2] * w1 + color2[2] * w2),
	];
	return `rgb(${rgb[0]},${rgb[1]},${rgb[2]})`;
}
function KPIIndictor({ label, value, color }) {
	return (
		<div
			style={{
				flexGrow: 1,
				borderRadius: 5,
				background: "#383838",
				textAlign: "center",
				padding: 6,

				border: "1px solid white",
				boxShadow: "0 3px 10px rgb(150 150 150 / 0.3)",
			}}
		>
			<h1
				style={{
					fontSize: 20,
					color: color ?? "#3f3",
				}}
			>
				{["NaN", "Infinity", "-Infinity"].indexOf(value) >= 0
					? "- -"
					: value}
			</h1>
			<p>{label}</p>
		</div>
	);
}
