import React, { Component } from "react";
import { connect } from "react-redux";
import { defaultConfig } from "./defaultConfigs/gauge";
import { guid } from "../../../../util";
import PopoutColorPicker from "../../../../components/PopoutColorPicker";
import {
	Dropdown,
	Stack,
	TextField,
	Separator,
	ChoiceGroup,
	Checkbox,
	Slider,
} from "@fluentui/react";
import SearchableDropdown from "../../../../components/SearchableDropdown";
import SystemDataItemSelector from "../../../../components/SystemDataItemSelector";
import CodeMirror from "../../../../components/CodeMirror6/CodeMirror";

class GaugeForm extends Component {
	state = defaultConfig();

	componentDidMount() {
		// populates fields when editing
		const { tile } = this.props.ui.modal;
		if (tile && !tile.properties.type.default) {
			this.setState({ ...tile.properties });
		}
	}
	cleanColor(color) {
		if (color?.length == 6 || color?.length == 3) {
			return "#" + color;
		} else {
			return color;
		}
	}
	handleChange = (e) => {
		e.preventDefault();
		const { addData } = this.props;
		this.setState({ [e.target.name]: e.target.value }, () => {
			addData(this.state);
		});
	};
	handleChange2 = (key, val) => {
		const { addData } = this.props;
		this.setState({ [key]: val }, () => {
			addData(this.state);
		});
	};

	render() {
		if (!this?.props?.ui?.modal?.tile) {
			return <></>;
		}
		const { name, dataItem, min, max, unit } = this.state;
		const band1 = this.cleanColor(this.state.band1);
		const band2 = this.cleanColor(this.state.band2);
		const band3 = this.cleanColor(this.state.band3);
		const band1End = isNaN(parseFloat(this.state.band1End))
			? parseInt((min + (max - min)) / 3)
			: parseFloat(this.state.band1End);
		const band2End = isNaN(parseFloat(this.state.band2End))
			? parseInt(((min + (max - min)) * 2) / 3)
			: parseFloat(this.state.band2End);

		const { tile } = this.props.ui.modal;
		var definition = this.props.definition;
		for (var i in tile?.properties?.slotPath ?? []) {
			var slotMatch = definition.slots?.find(
				(el) => el.slotId == tile.properties.slotPath[i]
			);
			if (!slotMatch) break;
			definition =
				slotMatch.assetDefinition || slotMatch.systemDefinition;
		}
		var dataItemList = definition.dataItemDefinitions ?? [];
		return (
			<div className="component-form">
				<SystemDataItemSelector
					key={this.props.ui.modal.tile.id}
					onDataItemSelected={(val) => {
						console.log(val);
						this.handleChange2("dataItem", val.dataItem);
						this.handleChange2("slotPath", val.slotPath);
					}}
					dataItem={this.state.dataItem}
					slotPath={this.state.slotPath ?? []}
				></SystemDataItemSelector>
				<TextField
					value={unit}
					label="Unit"
					type="text"
					onChange={(e, val) => {
						this.handleChange2("unit", val);
					}}
					name="unit"
				/>
				<TextField
					label="Minimum"
					value={min}
					type="number"
					onChange={(e, val) => {
						this.handleChange2("min", val);
					}}
					name="min"
					required={true}
				/>

				<TextField
					value={max}
					label="Maximum"
					type="number"
					onChange={(e, val) => {
						this.handleChange2("max", val);
					}}
					name="max"
					required={true}
				/>
				<ChoiceGroup
					label="Style"
					options={[
						{ key: "default", text: "Default" },
						{ key: "dark", text: "Dark" },
						{ key: "simple", text: "Simple" },
					]}
					selectedKey={this.state.style}
					onChange={(e, val) => {
						this.handleChange2("style", val.key);
					}}
				></ChoiceGroup>
				<Checkbox
					label="Half dial"
					checked={this.state.half}
					onChange={(e, val) => {
						this.handleChange2("half", val);
					}}
				></Checkbox>
				<Separator>Band 1</Separator>
				<Stack
					tokens={{ childrenGap: 5 }}
					horizontal
					verticalAlign="center"
				>
					<PopoutColorPicker
						color={band1}
						onChange={(ev, color) => {
							this.handleChange2("band1", color.str);
						}}
					></PopoutColorPicker>
					<TextField
						value={band1}
						onChange={(ev, color) => {
							this.handleChange2("band1", color);
						}}
					></TextField>
					<TextField disabled value={min} readOnly></TextField>
					<span> to </span>
					<TextField
						value={band1End}
						onChange={(ev, val) => {
							this.handleChange2("band1End", val);
						}}
					></TextField>
				</Stack>
				<Separator>Band 2</Separator>
				<Stack
					tokens={{ childrenGap: 5 }}
					horizontal
					verticalAlign="center"
				>
					<PopoutColorPicker
						color={band2}
						onChange={(ev, color) => {
							this.handleChange2("band2", color.str);
						}}
					></PopoutColorPicker>
					<TextField
						value={band2}
						onChange={(ev, color) => {
							this.handleChange2("band2", color);
						}}
					></TextField>
					<TextField
						value={band1End}
						onChange={(ev, val) => {
							this.handleChange2("band1End", val);
						}}
					></TextField>
					<span> to </span>
					<TextField
						value={band2End}
						onChange={(ev, val) => {
							this.handleChange2("band2End", val);
						}}
					></TextField>
				</Stack>
				<Separator>Band 3</Separator>
				<Stack
					tokens={{ childrenGap: 5 }}
					horizontal
					verticalAlign="center"
				>
					<PopoutColorPicker
						color={band3}
						onChange={(ev, color) => {
							this.handleChange2("band3", color.str);
						}}
					></PopoutColorPicker>
					<TextField
						value={band3}
						onChange={(ev, color) => {
							this.handleChange2("band3", color);
						}}
					></TextField>
					<TextField
						value={band2End ?? parseInt(min + (max - min) * 0.666)}
						onChange={(ev, val) => {
							this.handleChange2("band2End", val);
						}}
					></TextField>
					<span> to </span>
					<TextField disabled value={max} readOnly></TextField>
				</Stack>
				<Slider
					label="Tick label font size"
					min={12}
					max={36}
					value={this.state.fontNumbersSize}
					onChange={(val) => {
						this.handleChange2("fontNumbersSize", val);
					}}
					ariaValueText={(value) =>
						`${((100 * (value - 12)) / (36 - 12)).toFixed(
							0
						)} percent`
					}
					valueFormat={(value) =>
						`${((100 * (value - 12)) / (36 - 12)).toFixed(0)}%`
					}
					showValue
				></Slider>
				<Slider
					label="Title font size"
					min={12}
					max={36}
					value={this.state.fontTitleSize}
					onChange={(val) => {
						this.handleChange2("fontTitleSize", val);
					}}
					ariaValueText={(value) =>
						`${((100 * (value - 12)) / (36 - 12)).toFixed(
							0
						)} percent`
					}
					valueFormat={(value) =>
						`${((100 * (value - 12)) / (36 - 12)).toFixed(0)}%`
					}
					showValue
				></Slider>
				<Slider
					label="Value font size"
					min={12}
					max={36}
					value={this.state.fontValueSize}
					onChange={(val) => {
						this.handleChange2("fontValueSize", val);
					}}
					ariaValueText={(value) =>
						`${((100 * (value - 12)) / (36 - 12)).toFixed(
							0
						)} percent`
					}
					valueFormat={(value) =>
						`${((100 * (value - 12)) / (36 - 12)).toFixed(0)}%`
					}
					showValue
				></Slider>
				<Slider
					label="Units font size"
					min={12}
					max={36}
					value={this.state.fontUnitsSize}
					onChange={(val) => {
						this.handleChange2("fontUnitsSize", val);
					}}
					ariaValueText={(value) =>
						`${((100 * (value - 12)) / (36 - 12)).toFixed(
							0
						)} percent`
					}
					valueFormat={(value) =>
						`${((100 * (value - 12)) / (36 - 12)).toFixed(0)}%`
					}
					showValue
				></Slider>
			</div>
		);
	}
}

function mapStateToProps({ ui, definition }) {
	return { ui, definition };
}

export default connect(mapStateToProps)(GaugeForm);

// <span className='color-picker-color'>{band3}</span>
